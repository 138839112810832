<template>
  <div class="module-title">
    <div class="module-title-text">
      <slot name="text">默认文本</slot>
    </div>
    <div class="module-title-more">
      <slot name="more">默认更多</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTitle',
}
</script>

<style scoped lang="less">
.module-title {
  font-size: 20px;
  //font-family:PingFangSC,"Microsoft YaHei", PingFangSC-Medium, PingFang SC, serif;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #363a44;
  line-height: 28px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-text {
    width: 96px;
    height: 28px;
    padding-left: 8px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #d3e3ff 100%
    );
    border-radius: 14px;
  }
  &-more {
    margin-right: 12px;
    color: #686b73;
    font-size: 16px;
  }
}
</style>
